import { globalEnvironment } from "./globalEnvironment";
import { labelsCatasia } from "../app/resource/labels";

//const baseURL = "http://154.53.33.221:8090";
//const baseURL = "https://c.catasia.co";
const baseURL = "";

export const environment = {
  environment: "pruebasv2",
  production: false,
  name: "pruebasv2",
  host: globalEnvironment,
  API_URL: `${baseURL}/api/private/`,
  API_LOGIN_URL: `${baseURL}/api/security/oauth/`,
  labels: labelsCatasia["version2.0"],
  //GUI
  theme: "green",
  logoLogin:
    "assets/layout/images/macondo/LogosMacondo/logo_macondo_grises.png",
  loginText: "SISTEMA DE GESTIÓN CATASTRAL ALCALDÍA DE MACONDO",
  textTopBar: null,
  logoTopBar:
    "assets/layout/images/macondo/LogosMacondo/logo_macondo_grises.png",
  logofooterEsri: "assets/layout/images/LogosEsri/Col blanco vertical.png",
  logofooterEsriHeight: "70px",
  logofooterCatasIA: "assets/layout/images/catasia/catasia-vertical-white.png",
  logofooterCatasIAHeight: "70px",
  logoLoginHeight: "100px",

  plantillaEmailIdOmitirResolucion: 6,

  desarrollo: true
};
